import React from 'react'
import Navbar from './Navbar/Navbar'
import Cta from './Cta/Cta'
import Hero from './Hero/Hero'
import BundletabTestimonial from './Bundle/BundletabTestimonial/BundletabTestimonial'

import Testimonials from './Courses/testimonial'
import Blog from './Blog/Blog'
import Footer from './Footer/Footer'

const HomePage = () => {
  return (
    <div>
      <Navbar />

      <Hero />
      <div className='heading text-center' style={{paddingTop: '8rem'}}>
        <span className='cybersecurity' style={{font: ' normal normal 600 20px/50px Sora'}}>
          CYBERSECURITY TRAINING COURSES
        </span>
        <h2
          className='beginerr_hero'
          style={{font: 'normal normal normal 33px/46px Sora', color: '#000000'}}
        >
          Beginners. Intermediaries. Professionals. <br /> Our courses create leaders out of all.
        </h2>
      </div>

      <div className='cybersecurityresponsive'>
        <div
          className='heading text-centerresponsive d-flex justify-content-center '
          style={{flexDirection: 'column', textAlign: 'center'}}
        >
          <span style={{font: ' normal normal 600 12px/36px Sora'}}>
            CYBERSECURITY TRAINING COURSES
          </span>
          <div style={{width: '93%'}}>
            <h2 style={{font: 'normal normal normal 26px/30px Sora', color: '#000000'}}>
              Beginners. Intermediaries. Professionals Our courses create leaders out of all.
            </h2>
          </div>
        </div>
      </div>

      <BundletabTestimonial />
      <div id='hompage-testimonials'>
        <Testimonials />
      </div>

      <div id='hompage-blog'>
        <Blog />
      </div>
      <Cta />

      <Footer />
    </div>
  )
}

export default HomePage
