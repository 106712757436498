import React, {useState} from 'react'

import './Signup.css'

import {useDispatch, useSelector} from 'react-redux'

import {useForm, Controller} from 'react-hook-form'
import {RegisterAction} from '../../Redux/Action/authAction'

import {useEffect} from 'react'
import Footer from '../Footer/Footer'
import Loginandpay from '../LoginandPay/Loginandpay'
import StripePay from './StripePay/Stripepay'
import paypall from '../../assets/images/paypall.png'

import str from '../../assets/images/str.png'
import {useLocation} from 'react-router-dom'

import Loader from '../Loader/loader'
import TimezoneSelect from 'react-timezone-select'
import {ToastContainer} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import mr from '../../assets/images/female.jpg'
import admin from '../../assets/images/maleimg.jpg'
import {useNavigate} from 'react-router-dom'
import {GetallbundleactionAction} from '../../Redux/Action/bundleAction'

import Navbar from '../Navbar/Navbar'
import {
  GetAllCountriesAction,
  GetStateByCountryAction,
  GetCityByStateAction,
} from '../../Redux/Action/locationAction'

import {AddTemporaryUserAction} from '../../Redux/Action/usersAction'
import axios from 'axios'
import {MdInfoOutline} from 'react-icons/md'

const Signup = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  // debugger
  const [loading, setLoading] = useState(false)

  const [selectedCountryISOCode, setSelectedCountryISOCode] = useState('')
  const [selectedStateISOCode, setSelectedStateISOCode] = useState('')
  const [selectedTimezone, setSelectedTimezone] = useState(
    Intl.DateTimeFormat().resolvedOptions().timeZone
  )

  useEffect(() => {
    setSelectedTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
  }, [])

  const formattedTimezone = selectedTimezone?.value || ''

  var newURL = window.location

  var split = newURL.href.split('&')

  const bundle_code = split[1]
  const [selectedOption, setSelectedOption] = useState('1')

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value)
  }

  const handlePhoneNumberChange = (event) => {
    const inputPhoneNumber = event.target.value
    const numericPhoneNumber = inputPhoneNumber.replace(/\D/g, '')
    setValue('PhoneNumber', numericPhoneNumber)
  }
  const [cardComplete, setCardComplete] = useState(false)
  const handleSubmit2 = (event) => {
    event.preventDefault()
    let paymethod
    if (selectedOption === 'paypal') {
      paymethod = 1
    } else if (selectedOption === 'stripe') {
      paymethod = 2
    }
  }

  const handleBuyCourse = () => {
    localStorage.getItem('paid_price')
  }
  const image = admin

  const allCountries = useSelector((state) => {
    return state.login.getAllCountriesList
  })

  useEffect(() => {
    dispatch(GetAllCountriesAction())
  }, [dispatch])

  const state = useSelector((state) => {
    return state.login.getStateByCountrList
  })

  useEffect(() => {
    dispatch(GetStateByCountryAction(selectedCountryISOCode))
  }, [dispatch, selectedCountryISOCode])

  useEffect(() => {}, [state])

  const city = useSelector((state) => {
    return state.login.getCityByStateList
  })

  useEffect(() => {
    dispatch(GetCityByStateAction(selectedCountryISOCode, selectedStateISOCode))
  }, [dispatch, selectedCountryISOCode, selectedStateISOCode])

  useEffect(() => {}, [city])

  const handleChange = (e) => {
    const selectedValue = e.target.value
    setSelectedCountryISOCode(selectedValue)
  }

  const handleStateChange = (e) => {
    const selectedState = e.target.value
    setSelectedStateISOCode(selectedState)
  }

  const url = new URL(window.location.href)
  const {
    control,
    register,
    handleSubmit,
    watch,
    setValue,
    clearErrors,
    getValues,
    formState: {errors},
  } = useForm()

  const [selectedOptionPrefix, setSelectedOptionPrefix] = useState('')

  const course_code = localStorage.getItem('courseCode')

  const Dates = {
    Bundle_code: split[1],
    MicrosoftCourseId: split[2],
    CompTIACourseId: split[3],
    MicrosoftStartDate: localStorage.getItem('microsoftStartdate'),
    MicrosoftEndDate: localStorage.getItem('microsoftEnddate'),
    CompTIABootcampStartDate: localStorage.getItem('comptiaStartdate'),
    CompTIABootcampEndDate: localStorage.getItem('comptiaEnddate'),
    MicrosoftTimezone: localStorage.getItem('MicrosoftTimezone'),
    CompTIATimezone: localStorage.getItem('CompTIATimezone'),
  }

  const onSubmit = (data) => {
    //debugger
    const formData = {}
    //setLoading(true);

    const {
      EducationLevel,
      otherEducation,
      EmploymentStatus,
      otherEmploymentStatus,
      HowYouGotToKnow,
      otherHowYouGotToKnow,
      PurposeOfTakingTheCourse,
      otherPurpose,
    } = data

    // Add all fields to the formData object
    formData.PrefixMrMissMrs = selectedOptionPrefix
    formData.FirstName = data.FirstName
    formData.Surname = data.Surname
    formData.Email = data.Email
    formData.PhoneNumber = data.PhoneNumber
    formData.Country = data.Country
    formData.state = data.state
    formData.timezone = formattedTimezone || selectedTimezone
    formData.City = data.City
    formData.AgeBracket = data.AgeBracket
    formData.YearsOfCyberSecurityExperience = data.YearsOfCyberSecurityExperience
    formData.InterestedInCyberheadRole = data.InterestedInCyberheadRole
    formData.Password = data.Password

    if (EducationLevel === 'Other' && otherEducation) {
      formData.EducationLevel = otherEducation
    } else {
      formData.EducationLevel = EducationLevel
    }

    if (PurposeOfTakingTheCourse === 'Other' && otherPurpose) {
      formData.PurposeOfTakingTheCourse = otherPurpose
    } else {
      formData.PurposeOfTakingTheCourse = PurposeOfTakingTheCourse
    }

    if (EmploymentStatus === 'Other' && otherEmploymentStatus) {
      formData.EmploymentStatus = otherEmploymentStatus
    } else {
      formData.EmploymentStatus = EmploymentStatus
    }

    if (HowYouGotToKnow === 'Other' && otherHowYouGotToKnow) {
      formData.HowYouGotToKnow = otherHowYouGotToKnow
    } else {
      formData.HowYouGotToKnow = HowYouGotToKnow
    }
    formData.Bundle_code = Dates.Bundle_code
    formData.MicrosoftCourseId = Dates.MicrosoftCourseId
    formData.CompTIACourseId = Dates.CompTIACourseId
    formData.CourseId = split[2]
    localStorage.setItem('formData', JSON.stringify(formData))

    dispatch(AddTemporaryUserAction(formData, navigate))
  }

  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const urlParams = searchParams.toString().split('&', '?')

  const handleSelectPrefixChange = (event) => {
    const selectedOptionPre = event.target.value
    setSelectedOptionPrefix(selectedOptionPre)
  }

  const validatePassword = (password) => {
    if (!password.trim()) {
      return 'Password cannot be empty'
    }

    const uppercaseRegex = /[A-Z]/
    const lowercaseRegex = /[a-z]/
    const digitRegex = /\d/
    const specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/

    if (!uppercaseRegex.test(password)) {
      return 'Password must contain at least one uppercase letter'
    }
    if (!lowercaseRegex.test(password)) {
      return 'Password must contain at least one lowercase letter'
    }
    if (!digitRegex.test(password)) {
      return 'Password must contain at least one digit'
    }
    if (!specialCharacterRegex.test(password)) {
      return 'Password must contain at least one special character'
    }
    if (password.length < 8) {
      return 'Password must be at least 8 characters long'
    }

    return true
  }
  const categorizedData = useSelector((state) => state.login.getBundleall_list.categorizedData)

  const microsoftBootcampData = categorizedData && categorizedData.Microsoft_Bootcamp
  const Comptia_Bootcamp = categorizedData && categorizedData.Comptia_Bootcamp

  useEffect(() => {
    // debugger;
    dispatch(GetallbundleactionAction(bundle_code))
  }, [dispatch, bundle_code])

  const [passwordMatch, setPasswordMatch] = useState(true)
  const handleConfirmPasswordChange = (e) => {
    const confirmPasswordValue = e.target.value
    const passwordValue = getValues('Password')

    if (confirmPasswordValue === passwordValue) {
      setPasswordMatch(true)
    } else {
      setPasswordMatch(false)
    }
  }

  const [convertedPrice, setConvertedPrice] = useState(null)
  const [userCurrency, setUserCurrency] = useState('')
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const locationResponse = await axios.get('https://ipapi.co/json/')
        const Currency = locationResponse.data.currency
        setUserCurrency(Currency)
        if (!Currency) {
          throw new Error('Could not detect user currency')
        }
      } catch (error) {
        console.log('Error getting user location', error)
      }
    }

    fetchLocation()
  }, [])

  const convertCurrency = async (priceInGBP) => {
    if (!userCurrency) {
      console.log('User currency is not available yet')
      return null
    }

    try {
      const ratesResponse = await axios.get(
        'https://v6.exchangerate-api.com/v6/29c2c479ec40c46807bac2d4/latest/GBP'
      )
      const rate = ratesResponse.data.conversion_rates[userCurrency]

      if (!rate) {
        throw new Error('Exchange rate not available')
      }

      const convertedAmount = priceInGBP * rate
      const formattedPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: userCurrency,
      }).format(convertedAmount)

      setConvertedPrice(formattedPrice)
      return formattedPrice
    } catch (error) {
      console.error('Error in price conversion:', error)
      setConvertedPrice(null)
      return null
    }
  }

  convertCurrency(localStorage.getItem('price'))

  return (
    <div style={{background: 'white'}}>
      <div className='banner-content'>
        <h1
          className='banner-title'
          style={{
            position: 'absolute',
            top: '30%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            color: 'white',
            fontSize: '30px',
          }}
        >
          Sign up
        </h1>
      </div>
      <Navbar />

      <div>
        <div className='signUp_section'>
          <div
            className='container'
            // style={{zIndex: '99999999', boxShadow: '0 0 16px #e9e7e7', padding: '30px'}}
          >
            {/* {loading && (
              <div className='loader-container'>
                <Loader />
              </div>
            )} */}
            <div className='row'>
              <div className='col-12 mb-5'>
                <div className='card bg-light'>
                  {/* <div className='card-header'>
                    <h3 className='card-title'>Returning Customer ?</h3>
                  </div> */}
                  <div className='card-body p-5'>
                    <div className='user_alredyregister'>
                      <div
                        className='user_alreadyregister_btn fw-bold'
                        style={{
                          borderRadius: '25px',
                        }}
                      >
                        <Loginandpay
                          onClick={handleBuyCourse}
                          course_code={localStorage.getItem('course_code')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className='col-12'>
                <div className='card bg-light'>
                  <div className='card-body'>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className='row pt-10 '>
                        <>
                          <div className='col-md-8'>
                            <div className='contact'>
                              <div className='row sign_up_form'>
                                <div className='col-lg-2 col-md-2 col-sm-12'>
                                  <div className>
                                    <label for='inputState' className='form-label'>
                                      Prefix
                                    </label>
                                    <select
                                      id='inputState'
                                      className='form-select'
                                      style={{border: '1px solid #ccc'}}
                                      onChange={handleSelectPrefixChange}
                                      value={selectedOptionPrefix}
                                    >
                                      <option value='' disabled selected style={{color: '#495057'}}>
                                        Select{' '}
                                      </option>
                                      <option value='Mr'>Mr.</option>
                                      <option value='Mrs'>Mrs.</option>
                                      <option value='Miss'>Miss</option>
                                    </select>
                                    {errors.PrefixMrMissMrs && (
                                      <p
                                        className='alert_danger'
                                        role='alert'
                                        style={{marginTop: '1rem'}}
                                      >
                                        {errors.PrefixMrMissMrs?.message}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className='col-lg-5 col-md-5 col-sm-12'>
                                  <div className>
                                    <label for='inputFirstName' class='form-label'>
                                      First name <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <input
                                      control={control}
                                      type='FirstName'
                                      className='form-control'
                                      id='inputFirstName'
                                      style={{border: '1px solid #ccc'}}
                                      {...register('FirstName', {
                                        required: 'This is required field',
                                      })}
                                      aria-invalid={errors.FirstName ? 'true' : 'false'}
                                    />
                                    {errors.FirstName && (
                                      <p
                                        className='alert_danger'
                                        role='alert'
                                        style={{marginTop: '1rem'}}
                                      >
                                        {errors.FirstName?.message}
                                      </p>
                                    )}
                                  </div>
                                </div>

                                <div className='col-lg-5 col-md-5 col-sm-12'>
                                  <div className>
                                    <label for='inputSurName' className='form-label'>
                                      Surname <span style={{color: 'red'}}>*</span>
                                    </label>
                                    <input
                                      control={control}
                                      type='sur name'
                                      className='form-control'
                                      id='inputSurName'
                                      style={{border: '1px solid #ccc'}}
                                      {...register('Surname', {
                                        required: 'This is required field',
                                      })}
                                      aria-invalid={errors.Surname ? 'true' : 'false'}
                                    />
                                    {errors.Surname && (
                                      <p
                                        className='alert_danger'
                                        role='alert'
                                        style={{marginTop: '1rem'}}
                                      >
                                        {errors.Surname?.message}
                                      </p>
                                    )}
                                  </div>
                                </div>
                                <div className='col-6'>
                                  <label for='inputEmail4' className='form-label'>
                                    Email <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <input
                                    type='email'
                                    className='form-control'
                                    id='inputEmail4'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('Email', {
                                      required: 'This is required field',
                                    })}
                                    aria-invalid={errors.Email ? 'true' : 'false'}
                                  />
                                  {errors.Email && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.Email?.message}
                                    </p>
                                  )}
                                </div>

                                <div className='col-6'>
                                  <label htmlFor='inputEmail4' className='form-label'>
                                    Password <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <input
                                    type='password'
                                    className={`form-control ${
                                      errors.Password ? 'is-invalid' : ''
                                    }`}
                                    id='inputEmail4'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('Password', {
                                      required: 'Password is required',
                                      validate: (value) =>
                                        validatePassword(value) || 'Invalid password',
                                    })}
                                    onChange={(e) => {
                                      //handlePasswordChange(e)
                                      clearErrors('Password') // Clear errors for the 'Country' field
                                    }}
                                    aria-invalid={errors.Password ? 'true' : 'false'}
                                  />
                                  {errors.Password && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.Password.message}
                                    </p>
                                  )}
                                </div>

                                <div className='col-6'>
                                  <label htmlFor='confirmPassword' className='form-label'>
                                    Confirm Password <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <input
                                    type='password'
                                    className={`form-control ${
                                      errors.ConfirmPassword ? 'is-invalid' : ''
                                    }`}
                                    id='confirmPassword'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('ConfirmPassword', {
                                      required: 'Confirm Password is required',
                                    })}
                                    onChange={(e) => {
                                      handleConfirmPasswordChange(e)
                                      clearErrors('ConfirmPassword')
                                    }}
                                    aria-invalid={errors.ConfirmPassword ? 'true' : 'false'}
                                  />
                                  {!passwordMatch && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      Passwords do not match
                                    </p>
                                  )}
                                </div>

                                <div className='col-md-6'>
                                  <label for='inputcontact' className='form-label'>
                                    Telephone Number<span style={{color: 'red'}}>*</span>
                                  </label>
                                  <input
                                    type='tel'
                                    className='form-control'
                                    id='inputcontact'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('PhoneNumber', {
                                      required: 'This is required field',
                                      pattern: {
                                        value: /^[0-9]+$/,
                                        message: 'Please enter only numeric digits',
                                      },
                                    })}
                                    onChange={(e) => {
                                      handlePhoneNumberChange(e)
                                      clearErrors('PhoneNumber') // Clear errors for the 'Country' field
                                    }}
                                    // Handle change event
                                    value={watch('PhoneNumber')}
                                    aria-invalid={errors.PhoneNumber ? 'true' : 'false'}
                                  />
                                  {errors.PhoneNumber && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.PhoneNumber?.message}
                                    </p>
                                  )}
                                </div>

                                <div className='col-md-6'>
                                  {/* {formError && (
                                      <p className='alert_danger' role='alert'>
                                        {formError}
                                      </p>
                                    )} */}
                                  <label for='inputState' className='form-label'>
                                    Age <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <select
                                    id='inputState'
                                    className='form-select'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('AgeBracket', {
                                      required: true,
                                    })}
                                  >
                                    aria-invalid={errors.AgeBracket ? 'true' : 'false'}
                                    <option value='' disabled selected style={{color: '#495057'}}>
                                      Select{' '}
                                    </option>
                                    <option value='18-25'>18-25</option>
                                    <option value='26-30'>26-30</option>
                                    <option value='31-35'>31-35</option>
                                    <option value='36-40'>36-40</option>
                                    <option value='40+'>40+</option>
                                  </select>
                                  {errors.AgeBracket?.type === 'required' && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      This is required field
                                    </p>
                                  )}
                                </div>

                                <div className='col-md-6'>
                                  <label for='inputState' className='form-label'>
                                    <span className='required'>Country</span>
                                  </label>

                                  <div>
                                    <select
                                      id='inputState'
                                      className='form-select'
                                      style={{border: '1px solid #ccc'}}
                                      {...register('Country', {required: true})} // Use { required: true }
                                      aria-invalid={errors.Country ? 'true' : 'false'}
                                      value={selectedCountryISOCode}
                                      onChange={(e) => {
                                        handleChange(e)
                                        clearErrors('Country') // Clear errors for the 'Country' field
                                      }}
                                    >
                                      <option
                                        value=''
                                        disabled
                                        defaultValue
                                        style={{color: '#495057'}}
                                      >
                                        Select
                                      </option>
                                      {Object.values(allCountries).map((countryArr) =>
                                        countryArr.map((country, i) => (
                                          <option key={i} value={country.isoCode}>
                                            {country.name}
                                          </option>
                                        ))
                                      )}
                                    </select>

                                    {errors.Country && errors.Country.type === 'required' && (
                                      <p
                                        className='alert_danger'
                                        role='alert'
                                        style={{marginTop: '1rem'}}
                                      >
                                        This is required field
                                      </p>
                                    )}
                                  </div>
                                </div>

                                {state.count > 0 && (
                                  <>
                                    <div className='col-md-6'>
                                      <label for='inputState' className='form-label'>
                                        <span className='required'>State</span>
                                      </label>

                                      <div>
                                        <select
                                          id='inputState'
                                          className='form-select'
                                          style={{border: '1px solid #ccc'}}
                                          {...register('state', {
                                            required: 'This is required field',
                                          })}
                                          aria-invalid={errors.state ? 'true' : 'false'}
                                          value={selectedStateISOCode}
                                          onChange={(e) => {
                                            handleStateChange(e)
                                            clearErrors('state') // Clear errors for the 'Country' field
                                          }}
                                        >
                                          <option
                                            value=''
                                            disabled
                                            selected
                                            style={{color: '#495057'}}
                                          >
                                            Select
                                          </option>
                                          {state.states.map((item) => (
                                            <option key={item.isoCode} value={item.isoCode}>
                                              {item.name}
                                            </option>
                                          ))}
                                        </select>
                                        {errors.state && (
                                          <p
                                            className='alert_danger'
                                            role='alert'
                                            style={{marginTop: '1rem'}}
                                          >
                                            {errors.state?.message}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}
                                {city.count > 0 && (
                                  <>
                                    <div className='col-md-6'>
                                      <label for='inputState' className='form-label'>
                                        <span className='required'>City</span>
                                      </label>

                                      <div>
                                        <select
                                          id='inputState'
                                          className='form-select'
                                          style={{border: '1px solid #ccc'}}
                                          {...register('City', {
                                            required: 'This is required field',
                                          })}
                                          aria-invalid={errors.City ? 'true' : 'false'}
                                          aria-describedby='cityError'
                                        >
                                          <option
                                            value=''
                                            disabled
                                            selected
                                            style={{color: '#495057'}}
                                          >
                                            Select
                                          </option>

                                          {city.message &&
                                            city.message.map((city, index) => (
                                              <option key={index} value={city.name}>
                                                {city.name}
                                              </option>
                                            ))}
                                        </select>

                                        {errors.City && (
                                          <p
                                            className='alert_danger'
                                            role='alert'
                                            style={{marginTop: '1rem'}}
                                          >
                                            {errors.City?.message}
                                          </p>
                                        )}
                                      </div>
                                    </div>
                                  </>
                                )}

                                <div className='col-md-6'>
                                  <label for='inputState' className='form-label'>
                                    <span className='required'>Timezone</span>
                                  </label>

                                  <div>
                                    <TimezoneSelect
                                      value={selectedTimezone}
                                      onChange={setSelectedTimezone}
                                      placeholder='Select Timezone'
                                    />
                                  </div>
                                </div>

                                <div className='col-md-6'>
                                  <label for='inputState' className='form-label'>
                                    Years of Cyber Security Experience{' '}
                                    <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <select
                                    id='inputState'
                                    className='form-select'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('YearsOfCyberSecurityExperience', {
                                      required: 'This is required field',
                                    })}
                                    aria-invalid={
                                      errors.YearsOfCyberSecurityExperience ? 'true' : 'false'
                                    }
                                  >
                                    <option value='' disabled selected style={{color: '#495057'}}>
                                      Select{' '}
                                    </option>
                                    <option value='None' style={{color: '#495057'}}>
                                      None{' '}
                                    </option>
                                    <option value='1-2'>1-2</option>
                                    <option value='3-5'>3-5</option>

                                    <option value='5-7'>5-7</option>
                                    <option value='7+'>7+</option>
                                  </select>
                                  {errors.YearsOfCyberSecurityExperience && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.YearsOfCyberSecurityExperience?.message}
                                    </p>
                                  )}
                                </div>

                                <div className='col-md-6'>
                                  <label for='inputState' className='form-label'>
                                    Education Level: <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <select
                                    id='EducationLevel'
                                    className='form-select'
                                    style={{border: '1px solid #ccc', width: '100%'}}
                                    {...register('EducationLevel', {
                                      required: 'This is required field',
                                    })}
                                    aria-invalid={errors.EducationLevel ? 'true' : 'false'}
                                  >
                                    <option value='' disabled selected style={{color: '#495057'}}>
                                      Select{' '}
                                    </option>
                                    <option value='High-school' style={{color: '#495057'}}>
                                      {' '}
                                      High-school{' '}
                                    </option>

                                    <option value='University Degree'>University Degree</option>
                                    <option value='Other'>Other</option>
                                  </select>

                                  {watch('EducationLevel') === 'Other' && (
                                    <div className='col-md-12' style={{marginTop: '1rem'}}>
                                      <label htmlFor='otherEducation' className='form-label'>
                                        Please specify: <span style={{color: 'red'}}>*</span>
                                      </label>
                                      <textarea
                                        id='otherEducation'
                                        className='form-control border border-gray-600'
                                        {...register('otherEducation', {
                                          required:
                                            'This is required field when Education Level is "Other"',
                                        })}
                                        aria-invalid={errors.otherEducation ? 'true' : 'false'}
                                      ></textarea>
                                      {errors.otherEducation && (
                                        <p
                                          className='alert_danger'
                                          role='alert'
                                          style={{marginTop: '1rem'}}
                                        >
                                          {errors.otherEducation?.message}
                                        </p>
                                      )}
                                    </div>
                                  )}

                                  {errors.EducationLevel && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.EducationLevel?.message}
                                    </p>
                                  )}
                                </div>

                                <div className='col-md-6'>
                                  <label for='inputState' className='form-label'>
                                    Purpose of taking the Course{' '}
                                    <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <select
                                    id='inputState'
                                    className='form-select'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('PurposeOfTakingTheCourse', {
                                      required: 'This is required field',
                                    })}
                                    aria-invalid={
                                      errors.PurposeOfTakingTheCourse ? 'true' : 'false'
                                    }
                                  >
                                    <option value='' disabled selected style={{color: '#495057'}}>
                                      Select{' '}
                                    </option>
                                    <option value='To enhance my Skills' style={{color: '#495057'}}>
                                      {' '}
                                      To enhance my Skills{' '}
                                    </option>

                                    <option value='To learn about Microsoft Security'>
                                      To learn about Micosoft Security
                                    </option>
                                    <option value='To improve my chances of getting a job'>
                                      To improve my chances of getting a job
                                    </option>

                                    <option value='Other'>Other</option>
                                  </select>

                                  {watch('PurposeOfTakingTheCourse') === 'Other' && (
                                    <div className='col-md-12' style={{marginTop: '1rem'}}>
                                      <label htmlFor='otherPurpose' className='form-label'>
                                        Please specify: <span style={{color: 'red'}}>*</span>
                                      </label>
                                      <textarea
                                        id='otherPurpose'
                                        className='form-control border border-gray-600'
                                        {...register('otherPurpose', {
                                          required:
                                            'This is required field when Purpose is "Other"',
                                        })}
                                        aria-invalid={errors.otherPurpose ? 'true' : 'false'}
                                      ></textarea>
                                      {errors.otherPurpose && (
                                        <p
                                          className='alert_danger'
                                          role='alert'
                                          style={{marginTop: '1rem'}}
                                        >
                                          {errors.otherPurpose?.message}
                                        </p>
                                      )}
                                    </div>
                                  )}

                                  {errors.PurposeOfTakingTheCourse && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.PurposeOfTakingTheCourse?.message}
                                    </p>
                                  )}
                                </div>

                                <div className='col-md-6'>
                                  <label for='inputState' className='form-label'>
                                    Current employment status <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <select
                                    id='inputState'
                                    className='form-select'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('EmploymentStatus', {
                                      required: 'This is required field',
                                    })}
                                    aria-invalid={errors.EmploymentStatus ? 'true' : 'false'}
                                  >
                                    <option value='' disabled selected style={{color: '#495057'}}>
                                      Select{' '}
                                    </option>
                                    <option value='Unemployed' style={{color: '#495057'}}>
                                      {' '}
                                      Unemployed{' '}
                                    </option>

                                    <option value='Employed in a cyber security role'>
                                      Employed in a cyber security role
                                    </option>
                                    <option value='Employed in a unrelated cyber security role'>
                                      Employed in a unrelated cyber security role
                                    </option>
                                    <option value='Other'>Other</option>
                                  </select>
                                  {watch('EmploymentStatus') === 'Other' && (
                                    <div className='col-md-12' style={{marginTop: '1rem'}}>
                                      <label htmlFor='otherEmploymentStatus' className='form-label'>
                                        Please specify: <span style={{color: 'red'}}>*</span>
                                      </label>
                                      <textarea
                                        id='otherEmploymentStatus'
                                        className='form-control border border-gray-600'
                                        {...register('otherEmploymentStatus', {
                                          required:
                                            'This is required field when Employment Status is "Other"',
                                        })}
                                        aria-invalid={
                                          errors.otherEmploymentStatus ? 'true' : 'false'
                                        }
                                      ></textarea>
                                      {errors.otherEmploymentStatus && (
                                        <p
                                          className='alert_danger'
                                          role='alert'
                                          style={{marginTop: '1rem'}}
                                        >
                                          {errors.otherEmploymentStatus?.message}
                                        </p>
                                      )}
                                    </div>
                                  )}
                                  {errors.EmploymentStatus && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.EmploymentStatus?.message}
                                    </p>
                                  )}
                                </div>

                                <div className='col-md-6'>
                                  <label for='inputState' className='form-label'>
                                    I am interested in Cyberheads finding me a new job{' '}
                                    <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <select
                                    id='inputState'
                                    className='form-select'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('InterestedInCyberheadRole', {
                                      required: 'This is required field',
                                    })}
                                    aria-invalid={
                                      errors.InterestedInCyberheadRole ? 'true' : 'false'
                                    }
                                  >
                                    <option value='' disabled selected style={{color: '#495057'}}>
                                      Select{' '}
                                    </option>
                                    <option value='Yes' style={{color: '#495057'}}>
                                      Yes{' '}
                                    </option>
                                    <option value='No'>No</option>
                                  </select>
                                  {errors.InterestedInCyberheadRole && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.InterestedInCyberheadRole?.message}
                                    </p>
                                  )}
                                </div>

                                <div className='col-md-6'>
                                  <label for='inputState' className='form-label'>
                                    How did you find out about Cyberheads?{' '}
                                    <span style={{color: 'red'}}>*</span>
                                  </label>
                                  <select
                                    id='inputState'
                                    className='form-select'
                                    style={{border: '1px solid #ccc'}}
                                    {...register('HowYouGotToKnow', {
                                      required: 'This is required field',
                                    })}
                                    aria-invalid={errors.HowYouGotToKnow ? 'true' : 'false'}
                                  >
                                    <option value='' disabled selected style={{color: '#495057'}}>
                                      Select{' '}
                                    </option>
                                    <option value='Word of mouth' style={{color: '#495057'}}>
                                      Word of mouth{' '}
                                    </option>

                                    <option value='Referral'>Referral</option>
                                    <option value='Social Media'>Social Media</option>
                                    <option value='Google'>Google</option>
                                    <option value='Other'>Other</option>
                                  </select>

                                  {watch('HowYouGotToKnow') === 'Other' && (
                                    <div className='col-md-12' style={{marginTop: '1rem'}}>
                                      <label htmlFor='otherHowYouGotToKnow' className='form-label'>
                                        Please specify: <span style={{color: 'red'}}>*</span>
                                      </label>
                                      <textarea
                                        id='otherHowYouGotToKnow'
                                        className='form-control border border-gray-600'
                                        {...register('otherHowYouGotToKnow', {
                                          required:
                                            'This is required field when How You Got To Know is "Other"',
                                        })}
                                        aria-invalid={
                                          errors.otherHowYouGotToKnow ? 'true' : 'false'
                                        }
                                      ></textarea>
                                      {errors.otherHowYouGotToKnow && (
                                        <p
                                          className='alert_danger'
                                          role='alert'
                                          style={{marginTop: '1rem'}}
                                        >
                                          {errors.otherHowYouGotToKnow?.message}
                                        </p>
                                      )}
                                    </div>
                                  )}

                                  {errors.HowYouGotToKnow && (
                                    <p
                                      className='alert_danger'
                                      role='alert'
                                      style={{marginTop: '1rem'}}
                                    >
                                      {errors.HowYouGotToKnow?.message}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='col-md-4 course_details_section'>
                            <div className='container'>
                              <div className='row'>
                                <div className='col-12'>
                                  <div className='card mb-5'>
                                    <div className='card-body'>
                                      <h2 className='mb-5'>Your Order</h2>
                                      {url.search.length >= 80 ? (
                                        <div className='bundle_include_text'>
                                          <div>
                                            <div>
                                              <div className='mb-5'>
                                                <h3 className='mb-10'>
                                                  Bundle: {localStorage.getItem('Name')}
                                                </h3>
                                                <h4>Microsoft Bootcamp:</h4>
                                                {microsoftBootcampData ? (
                                                  microsoftBootcampData.map((course) => (
                                                    <div key={course.id}>
                                                      <h6>Course: {course.title}</h6>
                                                    </div>
                                                  ))
                                                ) : (
                                                  <p>No data available</p>
                                                )}
                                                <h6>
                                                  Dates:{' '}
                                                  {localStorage.getItem('userMicrosoftStartDate')} -{' '}
                                                  {localStorage.getItem('userMicrosoftEndDate')}
                                                </h6>
                                              </div>
                                              <div>
                                                <h4>CompTIA Bootcamp:</h4>
                                                {Comptia_Bootcamp ? (
                                                  Comptia_Bootcamp.map((course) => (
                                                    <div key={course.id}>
                                                      <h6> Course: {course.title}</h6>
                                                    </div>
                                                  ))
                                                ) : (
                                                  <p>No data available</p>
                                                )}
                                                <h6>
                                                  {' '}
                                                  Dates:{' '}
                                                  {localStorage.getItem(
                                                    'userCompTIABootcampStartDate'
                                                  )}{' '}
                                                  -{' '}
                                                  {localStorage.getItem(
                                                    'userCompTIABootcampEndDate'
                                                  )}
                                                </h6>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      ) : url.search.length >= 10 && url.search.length <= 70 ? (
                                        <div className='course_include_text'>
                                          <div>
                                            <h5>Course: {localStorage.getItem('title')}</h5>
                                            <h5>
                                              Dates: {localStorage.getItem('UserstartDate')} -{' '}
                                              {localStorage.getItem('UserendDate')}
                                            </h5>
                                            {/* <h5>Course: {localStorage.getItem('course_code')}</h5> */}
                                          </div>
                                        </div>
                                      ) : url.search.length <= 10 ? (
                                        <div className='course_include_text pt-8'>
                                          <div>
                                            <h4>
                                              <span>Course:</span> {localStorage.getItem('title')}
                                            </h4>
                                            <h4>
                                              <span>Validity:</span> 1 Year
                                            </h4>
                                          </div>
                                        </div>
                                      ) : (
                                        <div>No Data Present</div>
                                      )}

                                      <div className='col-md-12 mt-10'>
                                        <h4 className='total-price d-flex justify-content-between'>
                                          <span>Sub Total: </span>£{localStorage.getItem('price')}
                                        </h4>
                                        <h4 className='total-price d-flex justify-content-between'>
                                          <span>Tax: </span>£0
                                        </h4>
                                        <hr />
                                        <h4 className='total-price d-flex justify-content-between'>
                                          <span> Total: </span>£{localStorage.getItem('price')}
                                        </h4>
                                      </div>
                                      {convertedPrice && (
                                        <div
                                          className='d-flex'
                                          style={{
                                            backgroundColor: '#f8f8f8',
                                            padding: '8px 12px',
                                            borderRadius: '8px',
                                            // border: '1px solid #dcdcdc',
                                          }}
                                        >
                                          <MdInfoOutline
                                            className='fs-1'
                                            style={{marginRight: '5px'}}
                                          />
                                          <p className='fs-6' style={{margin: 0, color: '#555'}}>
                                            <strong>Approx Local Price:</strong> {convertedPrice}
                                          </p>
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className=''
                            style={{display: 'flex', alignItems: 'center', justifyContent: 'end'}}
                          >
                            <ToastContainer
                              position='bottom-right'
                              autoClose={5000}
                              hideProgressBar={false}
                              newestOnTop={false}
                              closeOnClick
                              rtl={false}
                              pauseOnFocusLoss
                              draggable
                              pauseOnHover
                              theme='light'
                            />
                            <div className='proceed_btn'>
                              <button
                                type='submit'
                                className='btn cyberbtnallpage'
                                style={{width: '100%'}}
                                //onClick={onSubmit}
                              >
                                Proceed
                              </button>
                            </div>
                          </div>
                        </>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Signup
