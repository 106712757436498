import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {Link, useNavigate} from 'react-router-dom'
import {PreviewDetailsAction} from '../../../../Redux/Action/courseAction'
import Table from 'react-bootstrap/Table'
import {Modal} from 'antd'
import {useForm} from 'react-hook-form'
import {StudentAction} from '../../../../Redux/Action/bundleAction'
import swal from 'sweetalert'
import moment from 'moment-timezone'
import axios from 'axios'
import {MdInfoOutline} from 'react-icons/md'
import {countries} from 'countries-list'
import Select from 'react-select'
const BuyBtn = () => {
  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()

  const dispatch = useDispatch()
  const navigate = useNavigate()
  var newURL = window.location
  var splitURL = newURL.href.split('/')
  const [selectedPreviewStartDate2, setselectedPreviewStartDate2] = useState(null)
  const [selectedPreviewEndDate2, setselectedPreviewEndDate2] = useState(null)
  const [selectedCourseId2, setselectedCourseId22] = useState(null)
  const [selectedCourseCode, setSelectedCourseCode2] = useState(null)
  const [selectedPrice2, setSelectedCoursePrice2] = useState(null)
  const [selectedTimezone2, setselectedTimezone2] = useState(null)
  const [selectedCourseName2, setselectedCourseName22] = useState(null)
  const [courseTimezone, setCourseTimezone] = useState(null)
  const [userstartDate, setUserstartDate] = useState(null)
  const [userendDate, setUserendDate] = useState(null)
  const [courseDatesId, setcourseDatesId] = useState(null)
  const [seats, setSeats] = useState(null)
  const [sales, setSales] = useState(null)
  const course_code = splitURL[4]
  const courseslug = splitURL[5]
  const studentId = localStorage.getItem('userid')
  const authToken = localStorage.getItem('authToken')

  localStorage.setItem('title', selectedCourseName2)
  localStorage.setItem('price', selectedPrice2)
  localStorage.setItem('startDate', selectedPreviewStartDate2)
  localStorage.setItem('endDate', selectedPreviewEndDate2)
  localStorage.setItem('CourseId', selectedCourseId2)
  localStorage.setItem('course_code', splitURL[4])
  localStorage.setItem('timezone', selectedTimezone2)
  localStorage.setItem('courseCode', splitURL[4])
  localStorage.setItem('courseTimezone', courseTimezone)
  localStorage.setItem('UserstartDate', userstartDate)
  localStorage.setItem('UserendDate', userendDate)
  localStorage.setItem('courseDatesId', courseDatesId)
  const previewdetails = useSelector((state) => {
    return state.login.getpreviewdetailslist
  })

  let cdata = ''
  useEffect(() => {
    dispatch(PreviewDetailsAction(courseslug))
  }, [dispatch])

  const scrollTomicrosoft = () => {
    const element = document.getElementById('microsoft')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  const handlePreviewDateChange = (
    formattedStartDate,
    formattedEndDate,
    timezone,
    courseDatesId,
    seats,
    sales
  ) => {
    setselectedPreviewStartDate2(formattedStartDate)
    setselectedPreviewEndDate2(formattedEndDate)
    setCourseTimezone(timezone)
    setcourseDatesId(courseDatesId)
    setSeats(seats)
    setSales(sales)
  }

  // Scroll to 'bundletitle' on page load
  useEffect(() => {
    scrollTomicrosoft()
  }, [])

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const onSubmit = () => {
    // debugger
    if (sales < seats) {
      const formData = new FormData()
      formData.append('Bundle_code', 0)
      formData.append('studentId', studentId)
      formData.append('CourseId', selectedCourseId2)
      formData.append('CourseStartDate', selectedPreviewStartDate2)
      formData.append('CourseEndDate', selectedPreviewEndDate2)
      formData.append('courseTimezone', courseTimezone)
      formData.append('courseDatesId', courseDatesId)

      dispatch(StudentAction(formData, course_code, navigate, onSubmit))
    } else if (sales == seats) {
      swal({
        title: 'Seats not available',
        text: 'Seats not available for this date. Please contact admin.',
        icon: 'warning',
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      })
    }
  }

  const isFormFilled = () => {
    return (
      selectedCourseId2 &&
      selectedPreviewStartDate2 &&
      selectedPreviewStartDate2 &&
      selectedCourseName2 &&
      selectedPrice2 &&
      selectedCourseCode
    )
  }

  const handleClick = () => {
    localStorage.setItem('type', 'course')
    //localStorage.setItem("course_code", splitURL[4])
  }

  const userLocale = navigator.language || 'en-UK'
  let asiaTimezone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

  function formatDate(dateString, timezone) {
    if (dateString) {
      const inputDate = moment.tz(dateString, 'YYYY-MM-DD HH:mm:ss', timezone)
      const localDate = inputDate.clone().local()

      return localDate.format('YYYY-MM-DD HH:mm:ss')
    } else {
      return null
    }
  }

  const formatDateString = (date) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // To use 12-hour format
    }
    return new Date(date).toLocaleDateString('en-GB', options)
  }

  const [convertedPrice, setConvertedPrice] = useState(null)
  const [check, setCheck] = useState(false)

  const convertCurrency = async (priceInGBP, currency) => {
    if (!currency) {
      console.log('User currency is not available yet')
      return null
    }

    try {
      const ratesResponse = await axios.get(
        'https://v6.exchangerate-api.com/v6/29c2c479ec40c46807bac2d4/latest/GBP'
      )
      const rate = ratesResponse.data.conversion_rates[currency]

      if (!rate) {
        throw new Error('Exchange rate not available')
      }

      const convertedAmount = priceInGBP * rate
      const formattedPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).format(convertedAmount)

      setConvertedPrice(formattedPrice)
      return formattedPrice
    } catch (error) {
      console.error('Error in price conversion:', error)
      setConvertedPrice(null)
      return null
    }
  }

  const countryOptions = Object.entries(countries).map(([code, country]) => ({
    code,
    label: country.name,
    value: country.name,
    currency: country.currency[0] || 'USD'
    // value: country.currency[0],
  }))

  const handleChange = (selectedOption) => {
    convertCurrency(previewdetails.data.paid_price, selectedOption.currency)
  }

  return (
    <div>
      {previewdetails &&
        Object.entries(previewdetails).map(([key, course], i) => {
          if (key === 'data') {
            return (
              <div
                key={`key${i}`}
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}
              >
                <div>
                  <h3>£{course.paid_price}</h3>
                  <div className='d-flex'>
                    <p
                      className='fs-5 cursor-pointer mt-4'
                      onClick={() => setCheck(true)}
                      style={{textDecoration: 'underline'}}
                    >
                      <MdInfoOutline className='fs-2' style={{color: '#264aca'}} /> Check Price in
                      Different Currencies
                    </p>
                  </div>

                  {check && (
                    <div>
                      <div style={{width: '250px', marginLeft: '5px'}}>
                        <Select
                          styles={{padding: '0px'}}
                          placeholder='Select Country'
                          options={countryOptions}
                          isSearchable={true}
                          getOptionLabel={(e) => (
                            <div style={{display: 'flex', alignItems: 'center'}}>
                              <img
                                src={`https://flagcdn.com/w40/${e.code.toLowerCase()}.png`}
                                alt={e.label}
                                style={{width: 20, height: 15, marginRight: 10}}
                              />
                              {e.label}
                            </div>
                          )}
                          onChange={handleChange}
                        />
                      </div>
                      {convertedPrice && (
                        <div style={{marginLeft: '8px'}}>
                          <p className='fs-5 mt-5'>Equivalent Amount: {convertedPrice}</p>
                        </div>
                      )}
                    </div>
                  )}
                </div>
                <Link
                  to='#'
                  className='button button-rounded btn-blue'
                  type='primary'
                  onClick={(e) => {
                    e.preventDefault()
                    setselectedCourseId22(course.courseId)
                    setSelectedCourseCode2(course.course_code)
                    setSelectedCoursePrice2(course.paid_price)
                    setselectedCourseName22(course.title)
                    handleClick()
                    showModal()
                  }}
                >
                  BUY NOW
                </Link>
              </div>
            )
          }
          return null
        })}

      <Modal
        title='Dates Available'
        open={open}
        onCancel={hideModal}
        footer={null} // Remove the default footer (OK and Cancel buttons)
        centered
        wrapClassName='custom-modal' // Apply a custom CSS class for styling
        // style={{width: '100vw', overflowX: 'auto'}}
      >
        <div>
          <div className='container'>
            <div className='Mylearning' style={{width: '100%'}}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{border: '1px solid #f4f4f4'}}>
                  <div
                    className='book-view'
                    style={{
                      display: 'flex',
                      gap: '10px',
                      padding: '5px',
                      borderRadius: '4px',
                      background: '#264ACA',
                    }}
                  >
                    <h4 class='card-title align-items-start flex-column'>
                      <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                        {selectedCourseName2 ?? null}
                      </span>
                    </h4>
                  </div>

                  <Table
                    bordered
                    id='Elevenplusyear_castomer'
                    style={{
                      tableLayout: 'fixed',
                    }}
                  >
                    <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                      <tr>
                        <td width={40}></td>
                        <th>Start Date & Time</th>
                        <th>End Date & Time</th>
                        <th>Timezone</th>
                      </tr>

                      {previewdetails && previewdetails.dateRange !== undefined ? (
                        previewdetails.dateRange.length > 0 ? (
                          previewdetails.dateRange.map((dateRange, index) => {
                            return (
                              <React.Fragment key={index}>
                                <tr style={{border: '1px solid #CECFCF'}}>
                                  <td>
                                    <input
                                      type='radio'
                                      name={`dateType`}
                                      value={formatDate(dateRange.end_datetime, dateRange.timezone)}
                                      // checked={selectedPreviewStartDate2 === formattedStartDate}
                                      onChange={() => {
                                        setUserstartDate(
                                          formatDateString(
                                            formatDate(dateRange.start_datetime, dateRange.timezone)
                                          )
                                        )
                                        setUserendDate(
                                          formatDateString(
                                            formatDate(dateRange.end_datetime, dateRange.timezone)
                                          )
                                        )
                                        handlePreviewDateChange(
                                          dateRange.start_datetime,
                                          dateRange.end_datetime,
                                          dateRange.timezone,
                                          dateRange.datesId,
                                          dateRange.SeatsCount,
                                          dateRange.salesCount
                                        )
                                      }}
                                    />
                                  </td>
                                  <td>
                                    {formatDateString(
                                      formatDate(dateRange.start_datetime, dateRange.timezone)
                                    )}
                                  </td>
                                  <td>
                                    {formatDateString(
                                      formatDate(dateRange.end_datetime, dateRange.timezone)
                                    )}
                                  </td>
                                  <td>{asiaTimezone}</td>
                                </tr>
                              </React.Fragment>
                            )
                          })
                        ) : (
                          <tr>
                            <td colSpan='4' className='text-center'>
                              No Dates Available
                            </td>
                          </tr>
                        )
                      ) : (
                        <tr>
                          <td colSpan='4' className='text-center'>
                            No Dates Available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div>
                  {authToken && isFormFilled() ? (
                    <button
                      className='buttonlaunch button b-0px'
                      style={{
                        width: '20%',
                        justifyContent: 'end',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Submit
                    </button>
                  ) : isFormFilled() && sales < seats ? (
                    <Link
                      to={`/signup?&${selectedCourseCode}&${selectedCourseId2}&${selectedPreviewStartDate2}&${selectedPreviewEndDate2}`}
                      className='buttonlaunch button b-0px'
                      style={{
                        width: '20%',
                        justifyContent: 'end',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Submit
                    </Link>
                  ) : sales == seats ? (
                    <div>
                      <button
                        className='buttonlaunch button b-0px'
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          swal({
                            title: 'Seats not available',
                            text: 'Seats not available for this date. Please contact admin.',
                            icon: 'warning',
                            closeOnClickOutside: false,
                            buttons: {
                              cancel: {
                                text: 'OK',
                                value: null,
                                visible: true,
                                className: 'swal-button--confirm cyberbtnallpage',
                              },
                            },
                          })
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : (
                    <div>
                      <span style={{width: '200px', fontSize: '15px'}}>
                        Please Select The Dates.
                      </span>
                      <button
                        className='buttonlaunch button b-0px'
                        disabled
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BuyBtn
