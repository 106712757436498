import React, {useEffect, useState} from 'react'
import {useRef} from 'react'
import {Link, useNavigate} from 'react-router-dom'

import {useForm} from 'react-hook-form'
import {useDispatch, useSelector} from 'react-redux'

import {
  GetBundleaction,
  GetallbundleactionAction,
  StudentAction,
} from '../../../../Redux/Action/bundleAction'
import {Modal} from 'antd'

import Table from 'react-bootstrap/Table'
import swal from 'sweetalert'
import moment from 'moment-timezone'
import {countries} from 'countries-list'
import Select from 'react-select'
import axios from 'axios'
import {MdInfoOutline} from 'react-icons/md'

function BundlyBuyBtn() {
  const dispatch = useDispatch()
  var newURL = window.location

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }
  const navigate = useNavigate()
  var splitURL = newURL.href.split('/')

  const bundle_slug = splitURL[4]
  const Bundle_code = splitURL[5]

  const getbundle = useSelector((state) => {
    return state.login.getbundlebyid
  })
  let cdata = ''
  useEffect(() => {
    // debugger;
    dispatch(GetBundleaction(bundle_slug))
  }, [dispatch, bundle_slug])

  const categorizedData = useSelector((state) => state.login.getBundleall_list.categorizedData)

  const microsoftBootcampData = categorizedData && categorizedData.Microsoft_Bootcamp
  const Comptia_Bootcamp = categorizedData && categorizedData.Comptia_Bootcamp

  useEffect(() => {
    // debugger;
    dispatch(GetallbundleactionAction(bundle_slug))
  }, [dispatch, bundle_slug])

  const [selectedMicrosoftStartDate, setSelectedMicrosoftStartDate] = useState(null)
  const [selectedMicrosoftEndDate, setSelectedMicrosoftEndDate] = useState(null)
  const [selectedCompTIABootcampStartDate, setSelectedCompTIABootcampStartDate] = useState(null)
  const [selectedCompTIABootcampEndDate, setSelectedCompTIABootcampEndDate] = useState(null)
  const [MicrosoftDateCourseId, setSelectedMicrosoftDateCourseId] = useState(null)
  const [CompTIABootcampDateCourseId, setCompTIABootcampDateCourseId] = useState(null)
  const [MicrosoftTimezone, setMicrosoftTimezone] = useState(null)
  const [ComptiaTimezone, setComptiaTimezone] = useState(null)
  const [selectedPrice, setSelectedBundlePrice] = useState(null)
  const [selectedTimezone, setSelectedtimezone] = useState(null)
  const [MicrosoftdatesId, setMicrosoftDatesId] = useState(null)
  const [CompTIAdatesId, setCompTIADatesId] = useState(null)
  const [userMicrosoftStartDate, setUserMicrosoftStartDate] = useState(null)
  const [userMicrosoftEndDate, setUserMicrosoftEndDate] = useState(null)
  const [userCompTIABootcampStartDate, setUserCompTIABootcampStartDate] = useState(null)
  const [userCompTIABootcampEndDate, setUserCompTIABootcampEndDate] = useState(null)
  const [microsoftseats, setMicrosoftSeats] = useState(null)
  const [comptiaseats, setComptiaSeats] = useState(null)
  const [microsoftsales, setMicrosoftSales] = useState(null)
  const [comptiasales, setComptiaSales] = useState(null)

  localStorage.setItem('price', selectedPrice)

  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()
  const handleMicrosoftDateChange = (
    courseId,
    formattedStartDate,
    formattedEndDate,
    timezone,
    datesId,
    seats,
    sales
  ) => {
    setSelectedMicrosoftStartDate(formattedStartDate)
    setSelectedMicrosoftEndDate(formattedEndDate)
    setSelectedMicrosoftDateCourseId(courseId)
    setMicrosoftTimezone(timezone)
    setMicrosoftDatesId(datesId)
    setMicrosoftSeats(seats)
    setMicrosoftSales(sales)
  }

  const handleCompTIABootcampDateChange = (
    courseId,
    formattedStartDate,
    formattedEndDate,
    timezone,
    datesId,
    seats,
    sales
  ) => {
    setSelectedCompTIABootcampStartDate(formattedStartDate)
    setSelectedCompTIABootcampEndDate(formattedEndDate)
    setCompTIABootcampDateCourseId(courseId)
    setComptiaTimezone(timezone)
    setCompTIADatesId(datesId)
    setComptiaSeats(seats)
    setComptiaSales(sales)
  }
  const studentId = localStorage.getItem('userid')

  const authToken = localStorage.getItem('authToken')
  const data = {
    Bundle_code: Bundle_code,
    MicrosoftCourseId: MicrosoftDateCourseId,
    CompTIACourseId: CompTIABootcampDateCourseId,
    selectedMicrosoftStartDate: selectedMicrosoftStartDate,
    selectedMicrosoftEndDate: selectedMicrosoftEndDate,
    selectedCompTIABootcampStartDate: selectedCompTIABootcampStartDate,
    selectedCompTIABootcampEndDate: selectedCompTIABootcampEndDate,
  }

  const isFormFilled = () => {
    return (
      data.Bundle_code &&
      data.MicrosoftCourseId &&
      data.CompTIACourseId &&
      data.selectedMicrosoftStartDate &&
      data.selectedMicrosoftEndDate &&
      data.selectedCompTIABootcampStartDate &&
      data.selectedCompTIABootcampEndDate
    )
  }

  const onSubmit = () => {
    debugger

    if (comptiasales < comptiaseats && microsoftsales < microsoftseats) {
      const formData = new FormData()
      formData.append('studentId', studentId)
      formData.append('coursetype', 'bundle')
      formData.append('Bundle_code', Bundle_code)
      formData.append('MicrosoftCourseId', MicrosoftDateCourseId) // Include Microsoft course ID
      formData.append('CompTIACourseId', CompTIABootcampDateCourseId) // Include CompTIA Bootcamp course ID
      formData.append('MicrosoftStartDate', selectedMicrosoftStartDate)
      formData.append('MicrosoftEndDate', selectedMicrosoftEndDate)
      formData.append('CompTIABootcampStartDate', selectedCompTIABootcampStartDate)
      formData.append('CompTIABootcampEndDate', selectedCompTIABootcampEndDate)
      formData.append('timezone', selectedTimezone)
      formData.append('MicrosoftTimezone', MicrosoftTimezone)
      formData.append('CompTIATimezone', ComptiaTimezone)
      formData.append('MicrosoftdatesId', MicrosoftdatesId)
      formData.append('CompTIAdatesId', CompTIAdatesId)

      dispatch(StudentAction(formData, Bundle_code, navigate, onSubmit))
    } else if (comptiasales == comptiaseats || microsoftsales == microsoftseats) {
      swal({
        title: 'Seats not available',
        text: 'Seats not available for this date. Please contact admin.',
        icon: 'warning',
        closeOnClickOutside: false,
        buttons: {
          cancel: {
            text: 'OK',
            value: null,
            visible: true,
            className: 'swal-button--confirm cyberbtnallpage',
          },
        },
      })
    }
  }
  const bundletitleRef = useRef()

  const scrollToBundletitle = () => {
    const element = document.getElementById('bundletitle')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {
    scrollToBundletitle()
  }, [])

  localStorage.setItem('microsoftStartdate', selectedMicrosoftStartDate)
  localStorage.setItem('microsoftEnddate', selectedMicrosoftEndDate)
  localStorage.setItem('comptiaStartdate', selectedCompTIABootcampStartDate)
  localStorage.setItem('comptiaEnddate', selectedCompTIABootcampEndDate)
  localStorage.setItem('type', 'bundle')
  localStorage.setItem('courseCode', Bundle_code)
  localStorage.setItem('timezone', selectedTimezone)
  localStorage.setItem('MicrosoftTimezone', MicrosoftTimezone)
  localStorage.setItem('CompTIATimezone', ComptiaTimezone)
  localStorage.setItem('userMicrosoftStartDate', userMicrosoftStartDate)
  localStorage.setItem('userMicrosoftEndDate', userMicrosoftEndDate)
  localStorage.setItem('userCompTIABootcampStartDate', userCompTIABootcampStartDate)
  localStorage.setItem('userCompTIABootcampEndDate', userCompTIABootcampEndDate)
  localStorage.setItem('MicrosoftdatesId', MicrosoftdatesId)
  localStorage.setItem('CompTIAdatesId', CompTIAdatesId)

  const userLocale = navigator.language || 'en-UK'
  let asiaTimezone = Intl.DateTimeFormat(userLocale).resolvedOptions().timeZone

  function formatDate(dateString, timezone) {
    if (dateString) {
      const inputDate = moment.tz(dateString, 'YYYY-MM-DD HH:mm:ss', timezone)
      const localDate = inputDate.clone().local()

      return localDate.format('YYYY-MM-DD HH:mm:ss')
    } else {
      return null
    }
  }

  const formatDateString = (date) => {
    const options = {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true, // To use 12-hour format
    }
    return new Date(date).toLocaleDateString('en-GB', options)
  }

  const [convertedPrice, setConvertedPrice] = useState(null)
  const [check, setCheck] = useState(false)

  const convertCurrency = async (priceInGBP, currency) => {
    if (!currency) {
      console.log('User currency is not available yet')
      return null
    }

    try {
      const ratesResponse = await axios.get(
        'https://v6.exchangerate-api.com/v6/29c2c479ec40c46807bac2d4/latest/GBP'
      )
      const rate = ratesResponse.data.conversion_rates[currency]

      if (!rate) {
        throw new Error('Exchange rate not available')
      }

      const convertedAmount = priceInGBP * rate
      const formattedPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).format(convertedAmount)

      setConvertedPrice(formattedPrice)
      return formattedPrice
    } catch (error) {
      console.error('Error in price conversion:', error)
      setConvertedPrice(null)
      return null
    }
  }

  const countryOptions = Object.entries(countries).map(([code, country]) => ({
    code,
    label: country.name,
    value: country.name,
    currency: country.currency[0] || 'USD'
  }))

  const handleChange = (selectedOption) => {
    convertCurrency(getbundle.data[0].price, selectedOption.currency)
  }

  return (
    <div>
      {getbundle &&
        Object.entries(getbundle).map(([key, course], i) => {
          if (key == 'data') {
            cdata = course
          }
        })}

      {cdata &&
        Object.entries(cdata).map(([key, item], index) => {
          return (
            <div>
              <div
                style={{display: 'flex', justifyContent: 'space-between', alignItems: 'baseline'}}
              >
                <h3>£{item.price}</h3>

                <Link
                  to='#'
                  className='button button-rounded btn-blue'
                  type='primary'
                  onClick={() => {
                    setSelectedBundlePrice(item.price)
                    showModal()
                  }}
                >
                  BUY NOW
                </Link>
              </div>
              <div className='d-flex'>
                <p
                  className='fs-5 cursor-pointer mt-4'
                  onClick={() => setCheck(true)}
                  style={{textDecoration: 'underline'}}
                >
                  <MdInfoOutline className='fs-2' style={{color: '#264aca'}} /> Check Price in
                  Different Currencies
                </p>
              </div>

              {check && (
                <div>
                  <div style={{width: '250px', marginLeft: '5px'}}>
                    <Select
                      styles={{padding: '0px'}}
                      placeholder='Select Country'
                      options={countryOptions}
                      isSearchable={true}
                      getOptionLabel={(e) => (
                        <div style={{display: 'flex', alignItems: 'center'}}>
                          <img
                            src={`https://flagcdn.com/w40/${e.code.toLowerCase()}.png`}
                            alt={e.label}
                            style={{width: 20, height: 15, marginRight: 10}}
                          />
                          {e.label}
                        </div>
                      )}
                      onChange={handleChange}
                    />
                  </div>
                  {convertedPrice && (
                    <div style={{marginLeft: '8px'}}>
                      <p className='fs-5 mt-5'>Equivalent Amount: {convertedPrice}</p>
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        })}

      <Modal
        title='Dates Available'
        open={open}
        onCancel={hideModal}
        footer={null}
        centered
        wrapClassName='custom-modal'
        // style={{width: '100vw', overflowX: 'auto'}}
      >
        <div>
          <div className='container'>
            <div className='Mylearning' style={{width: '100%'}}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                  <div
                    className='book-view'
                    style={{
                      display: 'flex',
                      gap: '10px',
                      padding: '5px',
                      borderRadius: '4px',
                      background: '#264ACA',
                    }}
                  >
                    <h4 class='card-title align-items-start flex-column'>
                      <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                        Microsoft Bootcamp
                      </span>
                    </h4>
                  </div>

                  <Table
                    bordered
                    id='Elevenplusyear_castomer'
                    style={{
                      tableLayout: 'fixed',
                    }}
                  >
                    <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                      <tr>
                        <td width={40}></td>
                        <th>Start Date & Time</th>
                        <th>End Date & Time</th>
                        <th>Timezone</th>
                      </tr>
                      {microsoftBootcampData ? (
                        microsoftBootcampData.map((course) => (
                          <React.Fragment key={course.id}>
                            {course.date_ranges && course.date_ranges.length > 0 ? (
                              course.date_ranges.map((dateRange, index) => (
                                <tr key={index} style={{border: '1px solid #CECFCF'}}>
                                  <td colSpan='2'>
                                    <input
                                      type='radio'
                                      name={`Microsoft`}
                                      value={formatDateString(
                                        formatDate(dateRange.start_datetime, dateRange.timezone)
                                      )}
                                      onChange={() => {
                                        setUserMicrosoftStartDate(
                                          formatDateString(
                                            formatDate(dateRange.start_datetime, dateRange.timezone)
                                          )
                                        )
                                        setUserMicrosoftEndDate(
                                          formatDateString(
                                            formatDate(dateRange.end_datetime, dateRange.timezone)
                                          )
                                        )
                                        handleMicrosoftDateChange(
                                          course.id,
                                          dateRange.start_datetime,
                                          dateRange.end_datetime,
                                          dateRange.timezone,
                                          dateRange.datesId,
                                          dateRange.SeatsCount,
                                          dateRange.salesCount
                                        )
                                      }}
                                    />
                                    &nbsp; &nbsp;
                                    {formatDateString(
                                      formatDate(dateRange.start_datetime, dateRange.timezone)
                                    )}
                                  </td>
                                  <td>
                                    {formatDateString(
                                      formatDate(dateRange.end_datetime, dateRange.timezone)
                                    )}
                                  </td>
                                  <td>{asiaTimezone}</td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan='4' className='text-center'>
                                  No dates available for this course
                                </td>
                              </tr>
                            )}
                          </React.Fragment>
                        ))
                      ) : (
                        <tr>
                          <td colSpan='4' className='text-center'>
                            No dates available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>

                  <div className='Mylearning' style={{width: '100%'}}>
                    <div>
                      <div
                        className='book-view'
                        style={{
                          display: 'flex',
                          gap: '10px',
                          padding: '5px',
                          borderRadius: '4px',
                          background: '#264ACA',
                        }}
                      >
                        <h4 class='card-title align-items-start flex-column'>
                          <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                            CompTIA Bootcamp
                          </span>
                        </h4>
                      </div>

                      <Table
                        bordered
                        id='Elevenplusyear_castomer'
                        style={{
                          tableLayout: 'fixed',
                          maxHeight: '300px', // Set the maximum height as needed
                          overflowY: 'auto', // Add a vertical scrollbar when content overflows
                        }}
                      >
                        <tbody
                          style={{
                            border: '1px solid #CECFCF',
                            fontSize: '15px',
                          }}
                        >
                          <tr>
                            <td width={40}></td>
                            <th>Start Date & Time</th>
                            <th>End Date & Time</th>
                            <th>Timezone</th>
                          </tr>
                          {Comptia_Bootcamp ? (
                            Comptia_Bootcamp.map((course) => (
                              <React.Fragment key={course.id}>
                                {course.date_ranges && course.date_ranges.length > 0 ? (
                                  course.date_ranges.map((dateRange, index) => (
                                    <tr key={index} style={{border: '1px solid #CECFCF'}}>
                                      <td colSpan='2'>
                                        <input
                                          type='radio'
                                          name={`Comptia`}
                                          value={formatDateString(
                                            formatDate(dateRange.start_datetime, dateRange.timezone)
                                          )}
                                          onChange={() => {
                                            setUserCompTIABootcampStartDate(
                                              formatDateString(
                                                formatDate(
                                                  dateRange.start_datetime,
                                                  dateRange.timezone
                                                )
                                              )
                                            )
                                            setUserCompTIABootcampEndDate(
                                              formatDateString(
                                                formatDate(
                                                  dateRange.end_datetime,
                                                  dateRange.timezone
                                                )
                                              )
                                            )
                                            handleCompTIABootcampDateChange(
                                              course.id,
                                              dateRange.start_datetime,
                                              dateRange.end_datetime,
                                              dateRange.timezone,
                                              dateRange.datesId,
                                              dateRange.SeatsCount,
                                              dateRange.salesCount
                                            )
                                          }}
                                        />
                                        &nbsp; &nbsp;
                                        {formatDateString(
                                          formatDate(dateRange.start_datetime, dateRange.timezone)
                                        )}
                                      </td>
                                      <td>
                                        {formatDateString(
                                          formatDate(dateRange.end_datetime, dateRange.timezone)
                                        )}
                                      </td>
                                      <td>{asiaTimezone}</td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan='4' className='text-center'>
                                      No dates available for this course
                                    </td>
                                  </tr>
                                )}
                              </React.Fragment>
                            ))
                          ) : (
                            <tr>
                              <td colSpan='4' className='text-center'>
                                No dates available
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {/* <div style={{paddingLeft: '83%'}}>
                    <button class='buttonlaunch button b-0px' type='submit'>
                      submit
                    </button>
                  </div> */}

                <div>
                  {authToken && isFormFilled() ? (
                    <button
                      className='buttonlaunch button b-0px'
                      style={{
                        width: '20%',
                        justifyContent: 'end',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Submit
                    </button>
                  ) : comptiasales == comptiaseats || microsoftsales == microsoftseats ? (
                    <div>
                      <button
                        className='buttonlaunch button b-0px'
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                        onClick={() => {
                          swal({
                            title: 'Seats not available',
                            text: 'Seats not available for this date. Please contact admin.',
                            icon: 'warning',
                            closeOnClickOutside: false,
                            buttons: {
                              cancel: {
                                text: 'OK',
                                value: null,
                                visible: true,
                                className: 'swal-button--confirm cyberbtnallpage',
                              },
                            },
                          })
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  ) : isFormFilled() ? (
                    <Link
                      to={`/signup?&${data.Bundle_code}&${data.MicrosoftCourseId}&${data.CompTIACourseId}&${data.selectedMicrosoftStartDate}&${data.selectedMicrosoftEndDate}&${data.selectedCompTIABootcampStartDate}&${data.selectedCompTIABootcampEndDate}`}
                      className='buttonlaunch button b-0px'
                      style={{
                        width: '20%',
                        justifyContent: 'end',
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      Submit
                    </Link>
                  ) : (
                    <div>
                      <span style={{width: '200px', fontSize: '15px'}}>
                        Please Select The Dates.
                      </span>
                      <button
                        className='buttonlaunch button b-0px'
                        disabled
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default BundlyBuyBtn
