import React, {useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import ReactCountryFlag from 'react-country-flag'
import {Button} from 'react-bootstrap'
import {PreviewDetailsAction} from '../../../../Redux/Action/courseAction'
import {
  StudentAction,
  GetBundleaction,
  GetallbundleactionAction,
} from '../../../../Redux/Action/bundleAction'
import {Modal} from 'antd'
import {Link, useNavigate} from 'react-router-dom'
import axios from 'axios'
import Table from 'react-bootstrap/Table'
import {MdInfoOutline} from 'react-icons/md'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import {countries} from 'countries-list'
import Select from 'react-select'

const ElearningBuyBtn = () => {
  const {
    register,
    handleSubmit,

    formState: {errors},
  } = useForm()
  const dispatch = useDispatch()
  var newURL = window.location
  var splitURL = newURL.href.split('/')
  const [selectedPreviewDate, setSelectedPreviewDate] = useState(null)
  const [selectedCourseId, setSelectedCourseId] = useState(null)
  const [selectedCourseCode, setSelectedCourseCode] = useState(null)
  const [selectedPrice, setSelectedCoursePrice] = useState(null)
  const [selectedCourseName, setSelectedCourseName] = useState(null)
  const course_code = splitURL[4]
  const courseslug = splitURL[5]
  const studentId = localStorage.getItem('userid')
  const authToken = localStorage.getItem('authToken')
  const previewdetails = useSelector((state) => {
    // debugger;
    return state.login.getpreviewdetailslist
  })

  useEffect(() => {
    // debugger
    dispatch(PreviewDetailsAction(courseslug))
  }, [dispatch])

  const scrollTolearning = () => {
    const element = document.getElementById('learning')
    if (element) {
      element.scrollIntoView({behavior: 'smooth'})
    }
  }

  useEffect(() => {
    scrollTolearning()
  }, [])

  const navigate = useNavigate()
  var splitURL = newURL.href.split('/')

  const bundle_slug = splitURL[4]
  const Bundle_code = splitURL[5]

  const [open, setOpen] = useState(false)

  const showModal = () => {
    setOpen(true)
  }

  const hideModal = () => {
    setOpen(false)
  }

  const onSubmit = () => {
    // debugger
    const formData = new FormData()
    formData.append('Bundle_code', 0)
    formData.append('studentId', studentId)
    formData.append('MicrosoftCourseId', selectedCourseId)
    formData.append('selectedMicrosoftDate', selectedPreviewDate)

    dispatch(StudentAction(formData, course_code, navigate, onSubmit))
  }
  const handlePreviewDateChange = (formattedDate) => {
    //debugger
    setSelectedPreviewDate(formattedDate)
  }

  const isFormFilled = () => {
    return (
      selectedCourseId &&
      selectedPreviewDate &&
      selectedCourseName &&
      selectedPrice &&
      selectedCourseCode
    )
  }

  const handleClick = () => {
    //localStorage.setItem('type', 'course')
    localStorage.setItem('course_code', splitURL[4])
  }

  const handleBuyButtonClick = () => {
    const course = previewdetails?.data
    if (course) {
      localStorage.setItem('price', course.paid_price)
      localStorage.setItem('title', course.title)
      localStorage.setItem('type', 'Elearning')
      localStorage.setItem('courseCode', splitURL[4])
    }
  }

  const [convertedPrice, setConvertedPrice] = useState(null)
  const [check, setCheck] = useState(false)

  const convertCurrency = async (priceInGBP, currency) => {
    if (!currency) {
      console.log('User currency is not available yet')
      return null
    }

    try {
      const ratesResponse = await axios.get(
        'https://v6.exchangerate-api.com/v6/29c2c479ec40c46807bac2d4/latest/GBP'
      )
      const rate = ratesResponse.data.conversion_rates[currency]

      if (!rate) {
        throw new Error('Exchange rate not available')
      }

      const convertedAmount = priceInGBP * rate
      const formattedPrice = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
      }).format(convertedAmount)

      setConvertedPrice(formattedPrice)
      return formattedPrice
    } catch (error) {
      console.error('Error in price conversion:', error)
      setConvertedPrice(null)
      return null
    }
  }

  const countryOptions = Object.entries(countries).map(([code, country]) => ({
    code,
    label: country.name,
    value: country.name,
    currency: country.currency[0] || 'USD'
  }))

  const handleChange = (selectedOption) => {
    convertCurrency(previewdetails.data.paid_price, selectedOption.currency)
  }

  return (
    <div>
      {previewdetails &&
        Object.entries(previewdetails).map(([key, course], i) => {
          if (key === 'data') {
            return (
              <div key={i}>
                <div>
                  {localStorage.getItem('authToken') ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                      }}
                    >
                      <div>
                        <h3>£{course.paid_price}</h3>
                        <div className='d-flex'>
                          <p
                            className='fs-5 cursor-pointer mt-4'
                            onClick={() => setCheck(true)}
                            style={{textDecoration: 'underline'}}
                          >
                            <MdInfoOutline className='fs-2' style={{color: '#264aca'}} /> Check
                            Price in Different Currencies
                          </p>
                        </div>

                        {check && (
                          <div>
                            <div style={{width: '250px', marginLeft: '5px'}}>
                              <Select
                                styles={{padding: '0px'}}
                                placeholder='Select Country'
                                options={countryOptions}
                                isSearchable={true}
                                getOptionLabel={(e) => (
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img
                                      src={`https://flagcdn.com/w40/${e.code.toLowerCase()}.png`}
                                      alt={e.label}
                                      style={{width: 20, height: 15, marginRight: 10}}
                                    />
                                    {e.label}
                                  </div>
                                )}
                                onChange={handleChange}
                              />
                            </div>
                            {convertedPrice && (
                              <div style={{marginLeft: '8px'}}>
                                <p className='fs-5 mt-5'>Equivalent Amount: {convertedPrice}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <Link to={`/insidebuycourse?/${course_code}`}>
                        <Button
                          className='button button-rounded btn-blue'
                          type='submit'
                          onClick={() => {
                            handleBuyButtonClick()
                          }}
                        >
                          Buy Now
                        </Button>
                      </Link>
                    </div>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                      }}
                    >
                      <div>
                        <h3>£{course.paid_price}</h3>
                        <div className='d-flex'>
                          <p
                            className='fs-5 cursor-pointer mt-4'
                            onClick={() => setCheck(true)}
                            style={{textDecoration: 'underline'}}
                          >
                            <MdInfoOutline className='fs-2' style={{color: '#264aca'}} /> Check
                            Price in Different Currencies
                          </p>
                        </div>

                        {check && (
                          <div>
                            <div style={{width: '250px', marginLeft: '5px'}}>
                              <Select
                                styles={{padding: '0px'}}
                                placeholder='Select Country'
                                options={countryOptions}
                                getOptionLabel={(e) => (
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                    <img
                                      src={`https://flagcdn.com/w40/${e.code.toLowerCase()}.png`}
                                      alt={e.label}
                                      style={{width: 20, height: 15, marginRight: 10}}
                                    />
                                    {e.label}
                                  </div>
                                )}
                                onChange={handleChange}
                              />
                            </div>
                            {convertedPrice && (
                              <div style={{marginLeft: '8px'}}>
                                <p className='fs-5 mt-5'>Equivalent Amount: {convertedPrice}</p>
                              </div>
                            )}
                          </div>
                        )}
                      </div>
                      <Link to={`/signup?/${course_code}`}>
                        <Button
                          className='button button-rounded btn-blue'
                          type='submit'
                          onClick={() => {
                            handleBuyButtonClick()
                          }}
                        >
                          Buy Now
                        </Button>
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            )
          }
          return null
        })}

      <Modal
        title='Dates Available'
        open={open}
        onCancel={hideModal}
        footer={null} // Remove the default footer (OK and Cancel buttons)
        centered
        wrapClassName='custom-modal' // Apply a custom CSS class for styling
        // style={{width: '100vw', overflowX: 'auto'}}
      >
        <div>
          <div className='container'>
            <div className='Mylearning' style={{width: '100%'}}>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div style={{border: '1px solid #f4f4f4'}}>
                  <div
                    className='book-view'
                    style={{
                      display: 'flex',
                      gap: '10px',
                      padding: '5px',
                      borderRadius: '4px',
                      background: '#264ACA',
                    }}
                  >
                    <h4 class='card-title align-items-start flex-column'>
                      <span class='card-label fw-bold text-800' style={{color: '#fff'}}>
                        {selectedCourseName ?? null}
                      </span>
                    </h4>
                  </div>

                  <Table
                    bordered
                    id='Elevenplusyear_castomer'
                    style={{
                      tableLayout: 'fixed',
                    }}
                  >
                    <tbody style={{border: '1px solid #CECFCF', fontSize: '15px'}}>
                      <tr>
                        <td colSpan='2'>Date</td>
                        <td colSpan='2'>Timezone</td>
                      </tr>

                      {previewdetails.dateRange ? (
                        previewdetails.dateRange.map((dateRange, index) => {
                          // Create a formatted date string
                          const formattedDate =
                            dateRange && dateRange.start_datetime && dateRange.end_datetime
                              ? `${new Date(dateRange.start_datetime).toLocaleDateString('en-UK', {
                                  month: 'numeric',
                                  day: 'numeric',
                                  year: 'numeric',
                                })} - ${new Date(dateRange.end_datetime).toLocaleDateString(
                                  'en-UK',
                                  {
                                    month: 'numeric',
                                    day: 'numeric',
                                    year: 'numeric',
                                  }
                                )}`
                              : dateRange
                              ? 'Invalid date range'
                              : 'N/A'

                          return (
                            <React.Fragment key={index}>
                              <tr style={{border: '1px solid #CECFCF'}}>
                                <td colSpan='2'>
                                  <input
                                    type='radio'
                                    name={`dateType_${previewdetails.id}`}
                                    value={formattedDate}
                                    checked={selectedPreviewDate === formattedDate}
                                    onChange={() => handlePreviewDateChange(formattedDate)}
                                  />
                                  &nbsp; &nbsp;
                                  {formattedDate ?? 'No Date Available'}
                                </td>
                                <td colSpan='2'>{dateRange?.timezone ?? null}</td>
                              </tr>
                            </React.Fragment>
                          )
                        })
                      ) : (
                        <tr>
                          <td colSpan='2'>No dates available</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                <div>
                  {authToken && isFormFilled() ? (
                    <button onClick={onSubmit} className='buttonlaunch button b-0px'>
                      Submit
                    </button>
                  ) : isFormFilled() ? (
                    <Link
                      to={`/signup?&${selectedCourseCode}&${selectedCourseId}&${selectedPreviewDate}`}
                      className='buttonlaunch button b-0px'
                    >
                      Submit
                    </Link>
                  ) : (
                    <div>
                      <span style={{width: '200px', fontSize: '15px'}}>
                        Please select the dates.
                      </span>
                      <button
                        className='buttonlaunch button b-0px'
                        disabled
                        style={{
                          width: '20%',
                          justifyContent: 'end',
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default ElearningBuyBtn
